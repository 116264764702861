import axios from 'axios'
import store from '../store';
// import router from '../router/index';
// import { Message } from 'element-ui';
import {
    msg
} from "../assets/tool/msg";

const service = axios.create({
    // baseURL: 'http://54.151.140.158:39421',
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    // timeout: 50000
})
// service.defaults.retry = 3; //断线重连 次数
// service.defaults.retryDelay = 1000; //断线重连 间隔时间

service.interceptors.request.use(function (config) {
    store.state.api_loading = true;

    let lang = sessionStorage.getItem('hichat_app_language');
    if (lang) {
        config.headers['language'] = lang;
    }
    let userid = sessionStorage.getItem('hichat_app_userid');
    if (userid) {
        config.headers['userid'] = userid;
    }
    let token = sessionStorage.getItem('hichat_app_token');
    if (userid) {
        config.headers['token'] = token;
    }
    config.headers['device-os'] = "web";
    config.headers['app-name'] = "HICHAT";

    return config;
}, function (error) {
    if (error) {
        store.state.api_loading = false;
        msg("error", error.response.status + " - " + error.response.statusText);
    }
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(function (response) {
    store.state.api_loading = false;

    if (response.data.code == 9400) {
        // 返回app钱包页面
        setTimeout(() => {
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
              if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.handleSessionExpired) {
                window.webkit.messageHandlers.handleSessionExpired.postMessage("");
              } else {
                console.error("window.webkit is not defined");
              }
            } else if (navigator.userAgent.match(/android/i)) {
               if (window.hiChatTask && typeof window.hiChatTask.handleSessionExpired === 'function') {
                window.hiChatTask.handleSessionExpired("Session expired");
              } else {
                console.error("hiChatTask interface is not available.");
              }
            }
        }, 2000);
    }

    return response;
}, function (error) {
    if (error) {
        store.state.api_loading = false;
        msg("error", error.response.status + " - " + error.response.statusText);

        // 返回app钱包页面
        setTimeout(() => {
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
              if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.handleSessionExpired) {
                window.webkit.messageHandlers.handleSessionExpired.postMessage("");
              } else {
                console.error("window.webkit is not defined");
              }
            } else if (navigator.userAgent.match(/android/i)) {
               if (window.hiChatTask && typeof window.hiChatTask.handleSessionExpired === 'function') {
                window.hiChatTask.handleSessionExpired("Session expired");
              } else {
                console.error("hiChatTask interface is not available.");
              }
            }
        }, 2000);

    }
    return Promise.reject(error);
});

export default service