import request from '../utils/request'

// 钱包积分首页
export const home = data => request({
    url: '/api/v1/hicoin/home',
    method: 'post',
    data
})
// 添加收款地址
export const payee_add = data => request({
    url: '/api/v1/hicoin/payee/add',
    method: 'post',
    data
})
// 编辑收款地址
export const payee_edit = data => request({
    url: '/api/v1/hicoin/payee/edit',
    method: 'post',
    data
})
// 收款地址列表
export const payee_list = data => request({
    url: '/api/v1/hicoin/payee/list',
    method: 'post',
    data
})


// 加密货币价格
export const getCurrencyAgentList = data => request({
    url: '/api/v1/hicoin/orders/getCurrencyAgentList',
    method: 'post',
    data
})
// 法币价格
export const getFiatPrice = data => request({
    url: '/api/v1/hicoin/orders/getFiatPrice',
    method: 'post',
    data
})
// 创建订单
export const orders_create = data => request({
    url: '/api/v1/hicoin/orders/create',
    method: 'post',
    data
})
// 订单列表
export const orders_list = data => request({
    url: '/api/v1/hicoin/orders/list',
    method: 'post',
    data
})
// 订单详情
export const orders_get = data => request({
    url: '/api/v1/hicoin/orders/get',
    method: 'post',
    data
})
// 上传支付凭证
export const orders_upload = data => request({
    url: '/api/v1/hicoin/orders/upload',
    method: 'post',
    data
})
// 去转账(用户出售的订单 并且status = 1)
export const transfer = data => request({
    url: '/api/v1/hicoin/orders/transfer',
    method: 'post',
    data
})
// 申诉(用户购买 status = 3 用户出售 status = 2)
export const appeal = data => request({
    url: '/api/v1/hicoin/orders/appeal',
    method: 'post',
    data
})
// 取消订单（用户购买 status = 2 用户出售 status = 1）
export const cancel = data => request({
    url: '/api/v1/hicoin/orders/cancel',
    method: 'post',
    data
})
// 取消申诉（status = 4）
export const cancelAppeal = data => request({
    url: '/api/v1/hicoin/orders/cancelAppeal',
    method: 'post',
    data
})
// 完成订单（用户出售 type = 2 并且 status = 3）
export const complete = data => request({
    url: '/api/v1/hicoin/orders/complete',
    method: 'post',
    data
})

//  获取文件系统认证token
export const getFSAuth = data => request({
    url: '/api/v1/hicoin/init/getFSAuth',
    method: 'post',
    data
})

//  获取文件系统认证token
export const getKycInfo = data => request({
    url: '/api/v1/hicoin/payee/getKycInfo',
    method: 'post',
    data
})
